const icon_path = require('../images/kreawi_logo_lila.png');

function init_app(elem_id, username, kreawi_token, kreawi_user_id) {

  var app =
    kreawi_quiz_client.Elm.KreawiQuizClient.init({
      node: document.getElementById(elem_id),
      flags: {
        username: username,
        password: "",
        kreawi_token: kreawi_token,
        kreawi_user_id: kreawi_user_id
      }
    });

  app.ports.askNotification.subscribe(function() {
    console.log("Asking for notification permission if necessary...");

    if (Notification.permission === "granted") {
      console.log("... permission already granted.");
      return;
    }

    Notification.requestPermission();
  });

  app.ports.notify.subscribe(function({ title, body }) {
    console.log(`Trying to notifiy about title '${title}' and body '${body}'...`);

    if (Notification.permission !== "granted") {
      console.log("No permission.");
      return;
    }

    try {
      // FIXME: This doesn't work on Android Chrome because "new Notification" was deprecated.
      //        A ServiceWorkerRegistration must be created to send notifications, 
      //        but we would need the URL of the JS file for that to work...
      const notification = new Notification(title, { body: body, icon: icon_path });
      console.log("Notification sent.");
    } catch (error) {
      console.error("Error sending notification: ")
      console.error(error);
    }
  });

  app.ports.modalOpen.subscribe(function(open) {
    // manually add class "modal-open" to body tag (outside elm div)
    // to prevent scrolling the main page when a modal is shown
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  });
}

module.exports = { init_app }
